import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useQueryParam, NumberParam } from 'use-query-params';
import { Link, navigate } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import { Colors, Fonts } from '@styles';
import Assets from '@assets';

const GlobalStyle = createGlobalStyle`
  @font-face {
		font-family: ${Fonts.regular};
		src: url(${Assets.fonts.Regular}) format('truetype');
	}
	@font-face {
		font-family: ${Fonts.bold};
		src: url(${Assets.fonts.Bold}) format('truetype');
	}

  body {
    background: ${Colors.bgPrimary};
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    font-family: ${Fonts.regular}, ${Fonts.bold}, sans-serif;
	}
	.menu-enter {
    opacity: 0;
  }
  .menu-enter-active {
    opacity: 1;
    transition: opacity 850ms;
  }
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100vh;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    height: 93.5vh;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex: 1;
  background: ${props => (props.side === 'left' ? Colors.primary : props.side === 'right' ? Colors.grey : '')};
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

const MenuTitleWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const MenuTitle = styled.div`
  font-size: 3.6rem;
  font-family: ${Fonts.bold};
  color: ${Colors.white};
  margin: 8% 11% 0;
  width: 38%;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 2.2rem;
    width: 48%;
  }
`;

const MenuImageWrapper = styled.div`
  position: relative;
  height: 65%;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const MenuImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  bottom: -2%;
`;

const MenuPersonaImage = styled.img`
  position: absolute;
  width: calc(100% * 0.17);
  bottom: ${({ percentageCompleted }) =>
    percentageCompleted === 100
      ? '41%'
      : percentageCompleted === 75
        ? '28%'
        : percentageCompleted === 50 ? '13%' : percentageCompleted === 25 ? '0%' : 0};

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: calc(100% * 0.185);
  }
`;

const MenuItem = styled(props => <Link {...props} />)`
	width: 100%;
	height: calc(100vh/4);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	text-decoration: none;
	background: ${Colors.bgPrimary};
	transition: background linear 0.3s;
	pointer-events: ${props => (props.disabled ? 'none' : 'unset')};

	&:hover {
		background: ${Colors.bgSecondary};
	}
`;

const MenuItemImage = styled.img`
  width: 90px;
  height: 90px;
  margin: 0 3% 0 6%;
  box-shadow: 0 0 15px rgb(0 0 0 / 30%);
  border-radius: 50%;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 75px;
    height: 75px;
  }
`;

const MenuItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  padding-right: 6rem;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding-right: 13%;
  }
`;

const MenuItemTitle = styled.div`
  font-size: 2rem;
  font-family: ${Fonts.bold};
  color: ${Colors.title};
  text-transform: uppercase;
  text-decoration: none;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 1.8rem;
  }
`;

const MenuItemSubTitle = styled.div`
  font-size: 1rem;
  font-weight: 300;
  color: ${Colors.subTitle};
  text-decoration: none;
  margin-top: 13px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 0.9rem;
  }
`;

const MenuItemSeparator = styled.div`
  height: 2px;
  width: 100%;
  background: ${Colors.white};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 4%;
  width: 45px;
  height: 45px;

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 25px;
    height: 25px;
  }
`;

const AudioControlWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 12px 20px;
  background: ${Colors.white};
  border-radius: 40px;
  color: ${Colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all linear 0.2s;
  opacity: ${({ playing }) => (playing ? 1 : 0.8)};
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);

  img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
  }
`;

const MenuIcon = ({ completed }) => {
  return (
    <IconWrapper>
      <img src={completed ? Assets.images.ArrowIcon : Assets.images.LockIcon} />
    </IconWrapper>
  );
};

const MENU_ITEMS = [
  {
    id: 'fly',
    path: '/fly',
    completedPath: '/fly?step=1&completed=1,2,3,4',
    icon: Assets.images.FlyIcon,
    title: 'Fly',
    subTitle: 'General information about Gene Pilot',
    dependsOn: 'menu',
  },
  {
    id: 'glide',
    path: '/glide',
    icon: Assets.images.GlideIcon,
    title: 'GLIDE',
    subTitle: 'Common concerns about Gene Pilot',
    dependsOn: 'fly',
  },
  {
    id: 'soar',
    path: '/soar',
    icon: Assets.images.SoarIcon,
    title: 'SOAR',
    subTitle: 'Videos of real cancer patients’ experience with Gene Pilot',
    dependsOn: 'glide',
  },
  {
    id: 'navigate',
    path: '/navigate',
    icon: Assets.images.NavigateIcon,
    title: 'NAVIGATE',
    subTitle: 'Create and print/email your own list of questions and view tips on how to talk to your doctor',
    dependsOn: 'soar',
  },
];

function iOS() {
  if (typeof navigator !== 'undefined') {
    return (
      [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
}

const Menu = () => {
  const audio = useRef(null);
  const [ autoplay ] = useQueryParam('autoplay', NumberParam);
  const [ inProp, setInProp ] = useState(false);
  const [ playingAudio, setPlayingAudio ] = useState(false);
  const [ completedSteps, setCompletedSteps ] = useState({});
  const [ percentageCompleted, setPercentageCompleted ] = useState(0);

  useEffect(() => {
    setInProp(true);

    if (localStorage.getItem('session-id')) {
      let steps = window.localStorage.getItem('completed-steps');
      if (steps) {
        steps = JSON.parse(steps);
        setCompletedSteps({ ...steps });

        delete steps.intro;
        delete steps.menu;
        delete steps.landing;
        const completedSteps = Object.keys(steps).length;
        setPercentageCompleted(completedSteps * 100 / 4);
      }

      audio.current = new Audio(Assets.audios.PageAudioMenu);
      audio.current.addEventListener('ended', handleAudioOnEnded);

      if (autoplay === 1 && !iOS()) {
        toggleAudio();
      }

      return () => {
        audio.current.pause();
        audio.current.removeEventListener('ended', handleAudioOnEnded);
        audio.current = null;
      };
    } else {
      navigate('/intro');
    }
  }, []);

  const handleAudioOnEnded = () => {
    setPlayingAudio(false);

    if (!completedSteps || !completedSteps.menu) {
      window.localStorage.setItem('completed-steps', JSON.stringify({ menu: true }));
      setCompletedSteps({ menu: true });
    }
  };

  const toggleAudio = () => {
    const newVal = !playingAudio;
    setPlayingAudio(newVal);

    if (newVal) {
      audio.current.play();
    } else {
      audio.current.pause();
    }
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <CSSTransition in={inProp} timeout={1000} classNames="menu" unmountOnExit>
        <Container>
          <MenuWrapper side="left">
            <MenuTitleWrapper>
              <MenuTitle>Stages In Gene Pilot</MenuTitle>
            </MenuTitleWrapper>
            <MenuImageWrapper>
              <MenuImage src={Assets.images.DNAImage} />
              <MenuPersonaImage src={Assets.images.PersonaImage} percentageCompleted={percentageCompleted} />
            </MenuImageWrapper>
            <AudioControlWrapper onClick={toggleAudio} playing={playingAudio}>
              <img src={Assets.images.SpeakerIcon} />
              {iOS() ? (
                <React.Fragment>{playingAudio ? 'Tap to pause' : 'Tap to play'}</React.Fragment>
              ) : (
                <React.Fragment>{playingAudio ? 'Click to pause' : 'Click to play'}</React.Fragment>
              )}
            </AudioControlWrapper>
          </MenuWrapper>
          <MenuWrapper side="right">
            {MENU_ITEMS.map((item, index) => (
              <React.Fragment key={index}>
                <MenuItem
                  to={item.completedPath && completedSteps[item.id] ? item.completedPath : item.path}
                  disabled={!completedSteps[item.dependsOn]}
                >
                  <MenuItemImage src={item.icon} />
                  <MenuItemTitleWrapper>
                    <MenuItemTitle>{item.title}</MenuItemTitle>
                    <MenuItemSubTitle>{item.subTitle}</MenuItemSubTitle>
                  </MenuItemTitleWrapper>
                  <MenuIcon completed={completedSteps[item.dependsOn]} />
                </MenuItem>
                <MenuItemSeparator />
              </React.Fragment>
            ))}
          </MenuWrapper>
        </Container>
      </CSSTransition>
    </React.Fragment>
  );
};

export default Menu;
